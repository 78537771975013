import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import { kebabCase } from 'lodash'
import { TrimStr } from '../components/Trim'
import Img from "gatsby-image"

const BriefCall = loadable(() => import('../components/BriefCall'));
const ToC = loadable(() => import('../components/ToC'));
const PortfolioRoll2 = loadable(() => import('../components/PortfolioRoll2'));
const SliderOpinions = loadable(() => import('../components/SliderOpinions'));

export const OfferPostTemplate = ({
  id,
  category,
  content,
  date,
  contentComponent,
  description,
  tags,
  title,
  slug,
  headings,
  helmet,
  location,
  featuredimage,
  backgroundimage,
  portfolios,
}) => {
  const PostContent = contentComponent || Content;
  // Filter portfolios based on matchig tags (max 3 for performance )
  let matchport = []

  if(portfolios !== undefined){
    //if for preview in cms (portfolios, posts & offers are not defined)
  portfolios.forEach(edge => {
    if((edge.node.frontmatter.tags.includes(title)) && (matchport.length < 3)){
      matchport.push(edge)
  }
  })}



  return (
    <>
    <section>
      {helmet}
      <div id="json"></div>
      <div className="mobile-noimg">
      {backgroundimage ?
        backgroundimage.childImageSharp ?
        <>
        <div>
        <Img fluid={backgroundimage.childImageSharp.fluid}
         alt={title}
         title={title}
         loading={'eager'}
         style={{height:'350px'}}
         imgStyle={{height:'350px',width:'100%',
         backgroundColor: '#111',zIndex:'-1',
         filter: 'brightness(0.3)'}}
        />
        </div>
        </>
        :
        <div className="mobile-noimg" style={{
        height:'350px',width:'100%',
        backgroundImage: `url(${backgroundimage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundColor: '#111',
        filter: 'brightness(0.3)'
      }}>
        </div>
        : <div style={{
        height:'350px',width:'100%',
        backgroundColor: '#111',
      }}>
        </div>
      }
      </div>
      <div className="container content ofpost" style={{position:'absolute',marginTop:'-20px'}}>
      <h1 className="title is-size-2 has-text-weight-bold is-bold-light oft"
      style={{color:'white',marginLeft:'35px'}}
      >
        {title}
      </h1>
      <span className="ofs" style={{marginLeft:'35px'}}><b>{category}</b></span>
      </div>
      <div className="container content">
        {/* Treść */}
        <div
          className="box"
          style={{ marginLeft: "3px", backgroundColor: "#f5f5f5" }}
        >

        <div className="columns">

          <div className="column" style={{display:'block',marginTop:'30px'}}>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
          <h2 style={{borderLeft:'10px solid #36b3d2',paddingLeft:'20px'}}>{description}</h2>
          </div>
          <br />
          <div className="no-mob" style={{paddingLeft:'30px'}}>
          {headings}
          </div>
          <br />
          </div>

          <div className="column no-mob" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          {featuredimage ?
            featuredimage.childImageSharp ?
            <Img fluid={featuredimage.childImageSharp.fluid}
             alt={title}
             title={title}
             style={{height:'350px',width:'100%'}}
             imgStyle={{border:'5px solid black',borderRadius:'10px'}}
            />
            :
           <img src={featuredimage} width="100%" height="250px" alt={title} loading="lazy" style={{border:'5px solid black',borderRadius:'10px'}} />
           : null
          }
          <br />
          </div>

        </div>

        <p className="ux" align="center" id="copywriting"><a className="btnoutline" href="#copywriting">⇩</a></p>



          <div className="column is-10 is-offset-1">


            <PostContent content={content} />


            <div style={{ textAlign: "center" }}></div>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <p className='subtitle'>Tags:</p>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `- tag`}>
                      <Link className="btn" to={`/tags/${kebabCase(tag)}/`} style={{padding:'10px',fontSize:'12px'}} >{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            <div style={{textAlign:'center'}}>
            <br />
            <Link className="btn" style={{fontSize:'1.1em'}}
            to="/contact-us/">Get in touch with us&nbsp; &nbsp;
            <img loading="lazy" width="15px" height="22px" style={{verticalAlign:'middle', marginRight:'5px'}}   alt="brief" src='/img/angle-right.svg'/></Link>
            <br /><br />
            </div>

          </div>



        </div>
        <br />
      </div>


    </section>



    {/*Powiązane realizacje */}
    <div className="mobile-noimg" style={{padding:'0',position:'relative'}}>
      {backgroundimage ?
        backgroundimage.childImageSharp ?
        <div>
        <Img fluid={backgroundimage.childImageSharp.fluid}
           alt={title}
           title={title}
           style={{height:'100%',zIndex:'-1',position:'absolute',width:'100%'}}
           imgStyle={{height:'100%',width:'100%',
           zIndex:'-1',objectFit:'cover',
           filter: 'brightness(0.3)'}}
          />
        <div className="matchport" style={{
          zIndex:'10',padding:'2.5% 5%',minHeight:'400px'
        }}>
        { matchport.length ?
            <>
            <p className="title" style={{color:'white',marginBottom:'-38px'}}>
            Related projects:
            </p>
            <PortfolioRoll2 posts={matchport} />
            </>
            : null
        }

        </div>
        </div>
        :
        <div style={{
        height:'100%',width:'100%',minHeight:'400px',
        backgroundImage: `url(${backgroundimage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundColor: 'black',
        filter: 'brightness(0.3)'
      }}>
      <div className="matchport" style={{
        zIndex:'10',padding:'2.5% 5%',minHeight:'400px'
      }}>
      { matchport.length ?
          <>
          <p className="title" style={{color:'white',marginBottom:'-38px'}}>
          Related projects:
          </p>
          <PortfolioRoll2 posts={matchport} />
          </>
          : null
      }
      </div>
        </div>
        : <div style={{
        minHeight:'400px',width:'100%',
        backgroundColor: '#121212',
      }}>
      <div className="matchport" style={{
        zIndex:'10',padding:'2.5% 5%',minHeight:'400px'
      }}>
      { matchport.length ?
          <>
          <p className="title" style={{color:'white',marginBottom:'-38px'}}>
          Related projects:
          </p>
          <PortfolioRoll2 posts={matchport} />
          </>
          : null
      }
      </div>
        </div>
      }

    </div>
    <BriefCall />
    <SliderOpinions />

    </>
  );
};

OfferPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  category: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  headings: PropTypes.object,
  id: PropTypes.string
};

const OfferPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { pdata: portfolios } = data;
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    if(window.innerWidth <= 769){
     setMobile(true)
    }
    if(post.frontmatter.english){
     document.getElementById('lang-switch').href = post.frontmatter.english
   }
 }, [post.frontmatter.english]);

  const blogPostingSchemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    url: 'https://e-multicontent.com'+ post.fields.slug,
    name: post.frontmatter.title.substring(0, 60),
    alternateName: post.frontmatter.title.substring(0, 60) || '',
    headline: post.frontmatter.description.substring(0, 120),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://e-multicontent.com'+ post.fields.slug,
    },
    author: {
      '@type': 'Organization',
      name: 'Zespół e-multiconent.com',
      url: 'https://e-multicontent.com/about-us/'
    },
    image: {
      '@type': 'ImageObject',
      url: 'https://e-multicontent.com'+post.frontmatter.featuredimage.publicURL,
    },
    datePublished: post.frontmatter.date,
    dateModified: post.frontmatter.date,
    publisher: {
      '@type': 'Person',
      name: post.frontmatter.author,
      logo: {
        '@type': 'ImageObject',
        url: 'https://e-multicontent.com/icons/icon-512x512.png',
      },
    },
    description: post.frontmatter.description.substring(0, 145)+'...',
  }

  return (
    <Layout>
    {console.log(mobile)}
    <nav className="breadcrumbs-nav section">
        <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to="/offer/">Offer</Link>&nbsp;&gt;&nbsp;<Link className="inline-link" to={"/offer/"+TrimStr(post.frontmatter.category)+'/'}>{post.frontmatter.category}</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600',color:'white'}}><b>{post.frontmatter.title}</b></span>
    </nav>
      <OfferPostTemplate
        content={post.html.replace(/href/g, "rel='noopener noreferrer' target='_blank' href")}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | e-multiconent">
            <title>{`${post.frontmatter.title.substring(0, 60)}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description.substring(0, 145) +
                "..."}`}
            />
            <meta
              name="image"
              content={
                "https://e-multicontent.com" +
                post.frontmatter.featuredimage.publicURL
              }
            />


            {/* Schema.org tags */}
            <script type='application/ld+json'>
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Offer",
                "item": "https://e-multicontent.com/offer/"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": post.frontmatter.category,
                "item": "https://e-multicontent.com/offer/"+TrimStr(post.frontmatter.category)+'/'
              },{
                "@type": "ListItem",
                "position": 3,
                "name": post.frontmatter.title,
                "item": "https://e-multicontent.com" + post.fields.slug
              }]
            })}
            </script>
            <script type='application/ld+json'>
              {JSON.stringify(blogPostingSchemaOrgJSONLD)}
            </script>


            {/* OpenGraph tags */}
            <meta
              property="og:title"
              content={post.frontmatter.title.substring(0, 60)}
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:description"
              content={post.frontmatter.description.substring(0, 145) + "..."}
            />
            <meta
              property="og:image"
              content={
                "https://e-multicontent.com" +
                post.frontmatter.featuredimage.publicURL
              }
            />
            <meta
              property="og:url"
              content={"https://e-multicontent.com" + post.fields.slug}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="e-multicontent.com" />
            <meta
              property="twitter:url"
              content={"https://e-multicontent.com" + post.fields.slug}
            />
            <meta
              name="twitter:title"
              content={post.frontmatter.title.substring(0, 60)}
            />
            <meta
              name="twitter:description"
              content={post.frontmatter.description.substring(0, 145) + "..."}
            />
            <meta
              name="twitter:image"
              content={
                "https://e-multicontent.com" +
                post.frontmatter.featuredimage.publicURL
              }
            />
            <link rel="alternate" href={post.frontmatter.english} hrefLang="pl-pl" />
            <link rel="canonical" href={"https://e-multicontent.com" + post.fields.slug}  />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredimage={mobile ? null : post.frontmatter.featuredimage}
        backgroundimage={mobile ? null : post.frontmatter.backgroundimage}
        slug={post.fields.slug}
        id={post.id}
        portfolios={portfolios.edges}
        category={post.frontmatter.category}
        headings={mobile ? null : <ToC headings={post.headings} />}
      />


    </Layout>
  );
};

OfferPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }),
  location: PropTypes.shape({}).isRequired
};

export default OfferPost;

export const pageQuery = graphql`
  query OffersByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      headings {
        value
        depth
      }
      html
      frontmatter {
        date(formatString: "DD. MM. YYYY")
        title
        category
        english
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              srcWebp
              src
              srcSet
              srcSetWebp
            }
          }
          publicURL
        }
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              src
              srcSet
              srcWebp
              srcSetWebp
            }
          }
          publicURL
        }
      }
    }
    pdata: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "portfolio-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            industry
            templateKey
            date(formatString: "DD. MM. YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
            backgroundimage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  srcWebp
                  src
                  srcSet
                  srcSetWebp
                }
              }
              publicURL
            }
            tags
          }
        }
      }
    }
  }
`;
